import autoView from "../components/RespondBox";//响应式盒子
import custBuySwiper from "../components/CustBuySwiper";//60商家滚动组件
import countToAct from "../components/CountToAct";//数字滚动(带背景)
import pie from "./components/Pie";//数字滚动(带背景)
import { getProductInfo, getCustBuyMsg, getTarget, getTop10, marketCenter, spart, hotproduct, hotcust, hotcenter, finishtask, findByPlatform } from "../api";
import { 
  activeReportCenter, 
  activeReportMatklGroup,
  activeReportMatkl,
  hostProduct,
  hostCust,
  hostShop,
  hostCenter,
  finishTask
 } from "./api";

// import getData from "./getData";
// import countTo from "vue-count-to";
import countTo from "./countTo.js";
import { SmileOutlined, MehOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    autoView,
    custBuySwiper,
    countToAct,
    countTo,
    pie,
    SmileOutlined
  },
  data() {
    return {
      index:0,//展示第几个商品的相关信息 默认第一个
      dataList:[],//活动商品列表 -》 用于键盘上下键获取商品信息 及 判断是否最后一个（提示）
      infoImg:"",//带信息的活动商品图
      productCode:"",//商品code -》 用于请求60商家 top10 进度
      
      changeLoading:true,//切换商品的loading开关 默认开启 请求第一次关闭
      // stepArr:[],//步骤条数量价格数据
      custList:[],//60商家 滚动数据
      topTenList:[],//top10 列表

      //百分比达成目标
      targetNum: 0,//目标数量
      // finishNum: 0,//完成数量
      // finishNumOld:0,//上次调用的完成数量
      unFinishNum: 0,//未完成数据
      unFinishNumArr:[],//未完成数据格式化后的数组['1', ',', '2', '3', '4']
      stepArr:[],//步骤条数量价格数据
      
      
      loadingControlArr:[false, false, false, false],//4项 0-请求完热销产品时开 1-请求完商家排名开 2-营销中心 3-完成进度
      timer1:null,//4项定时器
      timer2:null,
      timer3:null,
      timer4:null,
      finishNum1: 0,//完成数量
      finishNumOld1:0,//上次调用的完成数量
      finishNum2:0,//上次调用的完成数量
      finishNumOld2:0,//上次调用的完成数量
      sltForm:{//下拉框
        marketCenter:'',
        productGroupCode:'_',
        productCk:[],
      },

      type:1,//商家排名类型1是渠道商家，2专卖店商家就
      marketCenterList:[],//营销中心下拉数据数组
      spartList:[],//产品线下拉数据数组

      productCkArr:[],//
   

      hotproductList:[],//热销产品数据数组
      hotcustList:[],//商家排名数据数组
      hotcenterList:[],//营销中心数据数组
      finishtaskList:[],//完成进度数据
      qudaoWCL:0,//完成率1
      zhuanmaiWCL:0,//完成率2
      isChangeCpxTime:'',
      wlzTargetList1:[],//产品线完成率数组
      wlzTargetList2:[],//产品线完成率数组
    };
  },
  filters: {
    // 数据每个三位加，
    num: function(value) {
      // if(!value) return '0.00'
      value = value.toFixed(0);
      var intPart = Math.trunc(value);// 获取整数部分
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
      var floatPart = ""; // 预定义小数部分
      var value2Array = value.split(".");
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        floatPart = value2Array[1].toString(); // 拿到小数部分
        if (floatPart.length === 1) { // 补0,实际上用不着
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    }

  },
  computed: {
    finishRate(){
      // 完成率
      if(this.targetNum && this.finishNum) {
        if(this.finishNum >= this.targetNum){
          return 100
        }else{
          return parseInt((this.finishNum / this.targetNum) * 100);
        }
      }
      return 0;
    },
    isLoaing(){
      //接口报错状态下 不加载loadidng
      if(this.loadingControlArr.length == 0){
        return false;
      }
      //切换后
      if(!this.changeLoading){
        return false;
      }
      // if(this.loadingControlArr.every(item => item === false) && !this.changeLoading) {// 暂不用
      if(this.loadingControlArr.every(item => item === false)) {
        return false;
      }else{
        return true;
      }
    },
  },
  created() {
    const params = {
      method: 'marketingActivitiesRevelry',
      platform: 'dp',
      loginType: 'CS',
    };
    findByPlatform(params)
  },
  watch: {
    finishNum1(newNum, oldNum){
      //当前活动轮询时
      if(!this.changeLoading){
        this.finishNumOld1 = oldNum;
      }
    },
     finishNum2(newNum, oldNum){
      //当前活动轮询时
      if(!this.changeLoading){
        this.finishNumOld2 = oldNum;
      }
    },
    loadingControlArr: {
      handler() {
        if(this.loadingControlArr.every(item => item === false)){
          //全部加载完毕
          this.changeLoading = false;
        }
      },
      immediate: true,
      deep: true
    }
    
  },
  mounted() {

    //获取下拉
    this.getSltData();
    // //获取任务完成进度
    // this.getFinishtask();
    //获取信息
    this.getInfo();

    //屏幕自适应
    window.onresize = () => {
      this.$store.dispatch("user/changePageWidth");
      this.$store.dispatch("user/changePageHegiht");
    };
  },
  methods: {
    //获取营销中心/产品线下拉数据
    getSltData() {
      activeReportCenter().then(res => {
        if(res.data && res.data.length > 0){
          const arr_ = [{
            name:'全国',
            code:''
          }]
          this.marketCenterList = [...arr_, ...res.data];
        }
      }).catch(() => {
      });
      activeReportMatklGroup().then(res => {
        if(res.data && res.data.length > 0){
          // const arr_ = [{
          //   name:'全产品线',
          //   code:''
          // }]
          // this.spartList = [...arr_, ...res.data];
          // const data_ =  [{"name":"全产品品线","code":'00'},{"name":"电视","code":"20160119008_20160119013_20160119084_20160119063"},{"name":"海信冰冷","code":"20160119018_20160119022"},{"name":"容声冰冷","code":"20160119019_20160119023"},{"name":"空调","code":"20160119014_20160119015"},{"name":"洗衣机","code":"20160119020_20160119021"},{"name":"厨卫","code":"20160119085_20160119086"}]
          // this.spartList = data_;
          this.spartList = res.data;
        }
      }).catch(() => {
      });
      
    },

    
    //获取复选框物料组
    getProductCk() {
        let ckArr = []
        // if(this.sltForm.productGroupCode == 10){
        //   ckArr = [{
        //     label:'电视',
        //     value:'1',
        //   },
        //   {
        //     label:'vidaa电视',
        //     value:'3',
        //   },
        //   {
        //     label:'激光电视',
        //     value:'2',
        //   }]
        // }else if(this.sltForm.productGroupCode != ''){
        //   ckArr = [{
        //     label:'海信冰箱',
        //     value:'11',
        //   },
        //   {
        //     label:'海信冷柜',
        //     value:'22',
        //   }]
        // }
        // this.productCkArr =  ckArr;
        // this.productOldCkArr = ckArr;
        // this.sltForm.productCk = this.$util.getArr(ckArr, "value")
        this.productCkArr = [];
        this.sltForm.productCk = [];
        activeReportMatkl({productGroupCode: this.sltForm.productGroupCode}).then(res => {
          if(res.data && res.data.length > 0){
            this.productCkArr =  res.data.map(j=>{
              return({
                label:j.name,
                value:j.code,
              })
            });
            this.sltForm.productCk = this.$util.getArr(this.productCkArr, "value")
          }
          //先获取物料组再请求各项数据
          this.getInfo();
        }).catch(() => {
        });
    },
    //下拉切换 1 营销中心 2产品线
    sltChange(num){
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);
      clearInterval(this.timer4);
      this.changeLoading = true;
      if(num == 2){
        //若全产品线则不请求物料组
        if(this.sltForm.productGroupCode == '_'){
          this.productCkArr = [];
          this.sltForm.productCk = [];
          this.getInfo();
        }else{
          this.getProductCk();
        }
        this.isChangeCpxTime = new Date().getTime();
      }else{
        this.getInfo();
      }
    },
    onCkChange(ckVal) {
      // 如果选中的项还剩最后一个 -> 最后一个选中项禁用
      // if(ckVal.length == this.productCkArr.length - 1){
      if(ckVal.length == 1){
        // this.productCkArr = this.productOldCkArr.filter(j => ckVal.toString().indexOf(j.value) != -1)
        for (const item of this.productCkArr) {
          if(ckVal.toString().indexOf(item.value) != -1){
            item.disabled = true;
          }
        }
      }else{
        //全部可选
        this.productCkArr = this.productCkArr.map(j=>{return {...j, disabled:false}});
      }
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);
      clearInterval(this.timer4);
      this.changeLoading = true;
      this.getInfo();
    },
    // 产品线产品切换

    //商家类型切换
    typeChange(type){
      if(this.type == type){
        return;
      }else{
        this.type = type;
      }
      this.changeLoading = true;//暂不用
      clearInterval(this.timer2);
      this.timer2 = null;
      this.getHotcust();
    },
    // spart, hotproduct, hotcust, hotcenter, finishtask
    //  获取商品带信息的图片
    async getInfo() {
      //获取热销产品（明星产品）
      this.getHotproduct();
      //获取商家排名
      this.getHotcust();
      // //获取营销中心排名
      this.getHotcenter();
      //获取任务完成进度
      this.getFinishtask();
    },
    // 数字加逗号
    toThousands(num) {
      const result = []; let counter = 0
      num = (num || 0).toString().split('')
      for (let i = num.length - 1; i >= 0; i--) {
        counter++
        result.unshift(num[i])
        if (!(counter % 4) && i !== 0) { result.unshift(',') }
      }
      return result.join('')
    },

    //获取热销产品（明星产品）
    getHotproduct() {
      const params = {
        productGroupCode: this.sltForm.productGroupCode,
        marketCenter: this.sltForm.marketCenter,
        matklIds: this.sltForm.productCk.toString(),
      };
      this.$set(this.loadingControlArr,0,true)
      // this.hotproductList = [];
      hostProduct(params).then(res => {
        this.$set(this.loadingControlArr,0,false)
        if(res.data){
          this.hotproductList = res.data.map(j=>{
            return(
              {
                ...j,
                buyNum:this.toThousands(j.buyNum),
                syNum:this.toThousands(j.syNum),
              }
            )
          });
          //10s定时请求数据
          this.timer1 = setInterval(() => {
            this.getHotproduct();
            clearInterval(this.timer1);
          }, 10000);
        }else{
          this.loadingControlArr = [];
        }
      }).catch(() => {
        this.loadingControlArr = [];
      });;
    },

    //获取商家排名
    getHotcust() {
      const params = {
        productGroupCode: this.sltForm.productGroupCode,
        marketCenter: this.sltForm.marketCenter,
        matklIds: this.sltForm.productCk.toString(),
      };
      this.$set(this.loadingControlArr,1,true)
      // this.hotcustList = [];
      if(this.type == 1){
        //代理商排名
        hostCust(params).then(res => {
          this.$set(this.loadingControlArr,1,false)
          if(res.data){
            this.hotcustList = res.data.map(j=>{
              return(
                {
                  ...j,
                  buyNum:j.buyNum ? this.toThousands((j.buyNum / 10000).toFixed(0)) : 0,
                }
              )
            });
  
            //10s定时请求数据
            this.timer2 = setInterval(() => {
              this.getHotcust();
              clearInterval(this.timer2);
            }, 10000);
          }else{
            this.loadingControlArr = [];
          }
        }).catch(() => {
          this.loadingControlArr = [];
        });
      }else{
        // 专卖店排名
        hostShop(params).then(res => {
          this.$set(this.loadingControlArr,1,false)
          if(res.data){
            this.hotcustList = res.data.map(j=>{
              return(
                {
                  ...j,
                  buyNum:j.buyNum ? this.toThousands((j.buyNum / 10000).toFixed(0)) : 0,
                }
              )
            });
  
            //10s定时请求数据
            this.timer2 = setInterval(() => {
              this.getHotcust();
              clearInterval(this.timer2);
            }, 10000);
          }else{
            this.loadingControlArr = [];
          }
        }).catch(() => {
          this.loadingControlArr = [];
        });;
      }
      
    },
    
    //获取营销中心
    getHotcenter() {
      const params = {
        productGroupCode: this.sltForm.productGroupCode,
        marketCenter: this.sltForm.marketCenter,
        matklIds: this.sltForm.productCk.toString(),
      };
      this.$set(this.loadingControlArr,2,true)
      // this.hotcenterList = [];
      hostCenter(params).then(res => {
        this.$set(this.loadingControlArr,2,false)
        if(res.data){
          this.hotcenterList = res.data.map(j=>{
            return(
              {
                ...j,
                buyNum:j.buyNum ? this.toThousands((j.buyNum / 10000).toFixed(0)) : 0,
                rate: j.rate ? `${Number(j.rate * 100).toFixed(1)}%` : '0%'
              }
            )
          });
          if(this.hotcenterList && this.hotcenterList.length > 0){
            this.hotcenterList.forEach((item, index) => {
              if(index == 0){
                item.rn = 1
              }else if(item.rate == this.hotcenterList[index - 1].rate){
                item.rn = this.hotcenterList[index - 1].rn
              }else{
                item.rn = index + 1
              }
            })
          }
          //10s定时请求数据
          this.timer3 = setInterval(() => {
            this.getHotcenter();
            clearInterval(this.timer3);
          }, 10000);
        }else{
          this.loadingControlArr = [];
        }
      }).catch(() => {
        this.loadingControlArr = [];
      });;
    },

    //获取任务完成进度
    getFinishtask() {
      const params = {
        productGroupCode: this.sltForm.productGroupCode,
        marketCenter: this.sltForm.marketCenter,
        matklIds: this.sltForm.productCk.toString(),
      };
      this.$set(this.loadingControlArr,3,true)
      finishTask(params).then(res => {
        this.$set(this.loadingControlArr,3,false)
        // this.finishtaskList = [];
        if(res && res.data){
          // this.finishtaskList = res.data;

          // this.finishNum1 = res.data.qudao.finishPrice;//完成数量
          // this.finishNum2 = res.data.zhuanmai.finishPrice;//完成数量
          // this.qudaoWCL = res.data.qudao.targetMoney ? (res.data.qudao.finishPrice * 100/ res.data.qudao.targetMoney).toFixed(0) : 0;
          // // this.qudaoWCL = (Math.random() * 100).toFixed(0);
          // this.zhuanmaiWCL = res.data.zhuanmai.targetMoney ? (res.data.zhuanmai.finishPrice * 100 / res.data.zhuanmai.targetMoney ).toFixed(0) : 0;
          const finishNum1 = res.data.find(j => j.matklName == '全部' && j.targetType == 'QD').finishPrice;
          const finishNum2 = res.data.find(j => j.matklName == '全部' && j.targetType == 'SHOP').finishPrice;
          const targetMoney1 = res.data.find(j => j.matklName == '全部' && j.targetType == 'QD').targetMoney;
          const targetMoney2 = res.data.find(j => j.matklName == '全部' && j.targetType == 'SHOP').targetMoney;
          this.finishNum1 = finishNum1 || 0;
          this.finishNum2 = finishNum2 || 0;
          this.qudaoWCL = targetMoney1 ? (this.finishNum1 * 100/ targetMoney1).toFixed(0) : 0;
          this.zhuanmaiWCL = targetMoney2? (this.finishNum2 * 100 / targetMoney2 ).toFixed(0) : 0;

          const wlzTargetList1 = res.data.filter(j => j.matklName != '全部' && j.targetType == 'QD');
          const wlzTargetList2 = res.data.filter(j => j.matklName != '全部' && j.targetType == 'SHOP');
          this.wlzTargetList1 = wlzTargetList1.map(item => {
              return {
                ...item,
                wcl: item.targetMoney ? (item.finishPrice * 100 / item.targetMoney ).toFixed(0) : 0
              };
          });
          this.wlzTargetList2 = wlzTargetList2.map(item => {
              return {
                ...item,
                wcl: item.targetMoney ? (item.finishPrice * 100 / item.targetMoney ).toFixed(0) : 0
              };
          });
          //10s定时请求数据
          this.timer4 = setInterval(() => {
            this.getFinishtask();
            clearInterval(this.timer4);
          }, 10000);
        }else{
          this.loadingControlArr = [];
        }
      }).catch(() => {
        this.loadingControlArr = [];
      });;
    },


  },
  beforeDestroy() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
    clearInterval(this.timer4);
    this.timer1 = null;
    this.timer2 = null;
    this.timer3 = null;
    this.timer3 = null;
  }
};
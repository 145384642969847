/*
 * @Author: your name
 * @Date: 2021-11-15 14:35:51
 * @LastEditTime: 2022-05-19 10:23:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\marketingActivitiesNew\api.js
 */
import http from "@/utils/cisrequest";

//查询条件-营销中心
export function activeReportCenter(data) {
  return http({
    method: "get",
    url: "/b2b-rest/marketActivityReport/activeReportCenter/list",
    params:data
  });
}


//查询条件-品线
export function activeReportMatklGroup(data) {
  return http({
    method: "get",
    url: "/b2b-rest/marketActivityReport/activeReportMatklGroup/list",
    params:data
  });
}

//查询条件-物料组
export function activeReportMatkl(data) {
  return http({
    method: "get",
    url: "/b2b-rest/marketActivityReport/activeReportMatkl/list",
    params:data
  });
}


//产品排名
export function hostProduct(data) {
  return http({
    method: "get",
    url: "/b2b-rest/marketActivityReport/hostProduct/list",
    params:data
  });
}


//商家排名
export function hostCust(data) {
  return http({
    method: "get",
    url: "/b2b-rest/marketActivityReport/hostCust/list",
    params:data
  });
}

//专卖店商家排名
export function hostShop(data) {
  return http({
    method: "get",
    url: "/b2b-rest/marketActivityReport/hostShop/list",
    params:data
  });
}

//营销中心排名
export function hostCenter(data) {
  return http({
    method: "get",
    url: "/b2b-rest/marketActivityReport/hostCenter/list",
    params:data
  });
}

//任务完成进度
export function finishTask(data) {
  return http({
    method: "get",
    url: "/b2b-rest/marketActivityReport/finishTask/list",
    params:data
  });
}

